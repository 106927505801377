import React from 'react';

import DownloadIcons from '../DownloadIcons/DownloadIcons';
import Button from '../Button/Button';

import LayoutComponent from '../LayoutComponent/LayoutComponent';

import './registerAndDownload.scss';

const RegisterAndDownload = ({ content }) => {
  return (
    <div className="registerAndDownload-wrapper" id="registerAndDownload">
      <LayoutComponent
        background={`linear-gradient(30deg, #8C16FF 30%, #5A20FC  55%, #8C16FF 65%)`}
      >
        <div className="body-content">
          <p className="subtitle">{content.subtitle}</p>
          <p className="title">{content.title}</p>
          <div className="sections">
            <section>
              <div className="image-wrapper">
                <img
                  src={`${process.env.GATSBY_CMS_URL}${content.mobile_image.url}`}
                  alt={content.mobile_image_alt}
                />
              </div>
              <p className="titl">{content.mobile_title}</p>
              <div className="desc">{content.mobile_description}</div>
              <DownloadIcons />
            </section>
            <section>
              <div className="image-wrapper">
                <img
                  src={`${process.env.GATSBY_CMS_URL}${content.web_image.url}`}
                  alt={content.web_image_alt}
                />
              </div>
              <p className="titl">{content.web_title}</p>
              <div className="desc">{content.web_description}</div>
              <a
                href={content.button_url}
                target={'__blank'}
                rel={'noopener noreferrer nofollow'}
              >
                <Button
                  variant={'outlined'}
                  backgroundColor={'transparent'}
                  color={'white'}
                  text={content.button_text}
                  hover={content.hover}
                />
              </a>
            </section>
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default RegisterAndDownload;
