import React from "react";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import LayoutComponent from "../../components/LayoutComponent/LayoutComponent";
import FirstComponentMainPage from "../../components/FirstComponentMainPage/FirstComponentMainPage";
import TitleSubtitleCards from "../../components/TitleSubtitleCards/TitleSubtitleCards";
import TitleCards from "../../components/TitleCards/TitleCards";
import MitKinalACMO from "../../components/MitKinalACMO/MitKinalACMO";
import PaymentMethods from "../../components/PaymentMethods/PaymentMethods";
import RegisterAndDownload from "../../components/RegisterAndDownload/RegisterAndDownload";
import FullWidthImage from "../../components/FullWidthImage/FullWidthImage";
import DownloadNow from "../../components/DownloadNow/DownloadNow";
import ProductCategoriesComponent from "../../components/ProductCategoriesComponent/ProductCategoriesComponent";

import docs_background_image from "../../assets/images/Documentation/block-background-image.jpg";
import "./index.scss";
import Slider from "react-slick";
import IconCardComponent from "../../components/IconCardComponent/IconCardComponent";
import rehypeRaw from "rehype-raw";
import ReactMarkDown from "react-markdown";
import CMOButton from "../../components/Button/Button";
import { navigate } from "gatsby";

const IndexPage = (props) => {
  const location_href = props.location.pathname;
  const {
    firstComponentMainPage,
    titleSubtitleCards,
    fullWidthImage,
    SEO,
    partners,
    blogposts,
    epenztargep,
    qvik,
    documentation
  } = props.pageContext.content;

  const productCategoriesToCard = props.pageContext.content.productCategories;

  const {
    paymentMethods,
    mitKinalACMO,
    vallalkozniCsodas: { vallalkozniCsodas },
    kinekAjanljuk,
    registerAndDownload,
    productCategories,
  } = props.pageContext;

  const partnersSliderSettings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    className: "partners-slider",
    lazyload: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          arrows: false,
          infinite: true,
        },
      },
    ],
  };
  return (
    <>
      <LayoutHeaderFooter
        header_background_color={"#801DFF"}
        login_button={true}
        szechenyi={true}
        hover="hover_white_purple"
        SEO_title={SEO.title}
        SEO_description={SEO.description}
        SEO_keywords={SEO.keywords}
        SEO_location={location_href}
        SEO_image={SEO.image}
        main_class="index-page"
      >
        <FirstComponentMainPage
          primary_color={firstComponentMainPage.primary_color}
          secondary_color={firstComponentMainPage.secondary_color}
          content={firstComponentMainPage}
          image={`${process.env.GATSBY_CMS_URL}${firstComponentMainPage.image.url}`}
          onClick={() => {
            let item = document.getElementById("registerAndDownload");
            item.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        />

        <div loading="lazy" 
              className="docs-block" 
              style={{ 
                backgroundImage: `url(${docs_background_image})`,  
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"}}>
          <LayoutComponent>
            <div className="docs-block-content">
              <div className="docs-text">
                <div className="docs-title">
                  <p>{documentation.Title}</p>
                  </div>
                <div className="docs-description">
                  <ReactMarkDown
                    rehypePlugins={[rehypeRaw]}
                    children={documentation.Description}
                  />
                </div>
              </div>
              <CMOButton 
                      background_color={"#5107ff"}
                      color={"#FFFFFF"}
                      text={documentation.ButtonText} 
                      onClick={ () => {navigate("/gyik/epenztargep/ntak.vendeglato.szoftver-onallo-telepitese");} } />
            </div>
          </LayoutComponent>
        </div>
        
        <div className="epenztargep-block">
          <LayoutComponent background_color={"#868BFC"}>
            <div className="block-content">
              <div className="text">
                <div className="title">
                  <p>{epenztargep.Title}</p>
                  </div>
                <div className="description">
                  <ReactMarkDown
                    rehypePlugins={[rehypeRaw]}
                    children={epenztargep.MainDescription}
                  />
                  <CMOButton 
                      background_color={"#5107ff"}
                      color={"#FFFFFF"}
                      text={"Tovább az ajánlatokra!"} 
                      onClick={ () => {navigate("/epenztargep");} } />
                </div>
              </div>
              <img src={`${process.env.GATSBY_CMS_URL}${epenztargep.Image.url}`} loading="lazy"></img>
            </div>
          </LayoutComponent>
        </div>

        <div className="qvik-block">
          <LayoutComponent background_color={"#04bdb1"}>
            <div className="qvik-block-content">
            <img src={`${process.env.GATSBY_CMS_URL}${qvik.Image.url}`} loading="lazy"></img>
              <div className="text">
                <div className="title">
                  <p>{qvik.Title}</p>
                  </div>
                <div className="description">
                  <ReactMarkDown
                    rehypePlugins={[rehypeRaw]}
                    children={qvik.Description}
                  />
                  <CMOButton 
                      background_color={"#5107ff"}
                      color={"#FFFFFF"}
                      text={"Tovább a QVIK-re!"} 
                      onClick={ () => {navigate("/qvik-qr-kod-azonnali-mobilfizetes");} } />
                </div>
              </div>
              
            </div>
          </LayoutComponent>
        </div>

       

        
        <MitKinalACMO
          title={mitKinalACMO.title}
          cards={mitKinalACMO.cards}
          description={mitKinalACMO.description}
        />

        <TitleSubtitleCards
          title={titleSubtitleCards.title}
          subtitle={titleSubtitleCards.subtitle}
          content={titleSubtitleCards.content}
        />
        <ProductCategoriesComponent categories={productCategories} />
        <PaymentMethods
          title={
            "Fizetési lehetőségek, amivel a legtöbb CMO rendszer kompatibilis"
          }
          content={paymentMethods}
        />

        <div className="category-cards">
          {productCategoriesToCard.map((category, index) => (
            <IconCardComponent key={`category-`+index} category={category}></IconCardComponent>
          ))}
        </div>

        <DownloadNow
          content={{
            items: vallalkozniCsodas.vallakozniCsodasItem,
            primary_color: vallalkozniCsodas.primary_color,
            secondary_color: vallalkozniCsodas.secondary_color,
            button_text: vallalkozniCsodas.button_text,
            hover: vallalkozniCsodas.hover,
          }}
          onClick={() => {
            let item = document.getElementById("registerAndDownload");
            item.scrollIntoView({ behavior: "smooth" });
          }}
        />
        <FullWidthImage
          image={`${process.env.GATSBY_CMS_URL}${fullWidthImage.backgroundImage.url}`}
          image_alt={fullWidthImage.image_alt}
          content={{
            title: fullWidthImage.title,
            description: fullWidthImage.description,
          }}
          button_text={fullWidthImage.button_text}
          onClick={() => {
            let item = document.getElementById("registerAndDownload");
            item.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        />

        <TitleCards content={kinekAjanljuk} />
        <RegisterAndDownload content={registerAndDownload} />
        <h2>Kedvenc blogjaitok</h2>
        <div className="articles">
          {blogposts
            /*.sort(
              (post1, post2) =>
                new Date(post2.published_at).valueOf() -
                new Date(post1.published_at).valueOf()
            )*/
            .map((post, index) => {
              return (
                <a href={`/blog${post.slug}`} className="article" key={index}>
                  {post.thumbnail && (
                    <img
                      src={`${process.env.GATSBY_CMS_URL}${post.thumbnail.url}`}
                      alt={post.thumbnail_alt}
                      loading="lazy"
                    />
                  )}
                  <div className="date">
                    {new Date(post.createdAt).toLocaleDateString("hu-HU")}
                  </div>
                  <div className="reading_time">
                    Olvasási idő: {post.reading_time}
                  </div>
                  <div className="article_title">{post.title}</div>
                  <div className="article_desc">{post.short_description}</div>
                </a>
              );
            })}
        </div>
        <h2>Partnereink</h2>
        <div className="partners">
          <Slider {...partnersSliderSettings}>
            <div
              className="arukereso"
              style={{
                background: "transparent",
                textAlign: "center",
                padding: 0,
                margin: "0 auto",
                width: "120px",
              }}
            >
              <a
                title="Árukereső.hu"
                href="https://www.arukereso.hu/"
                style={{ display: "block", border: 0, padding: 0, margin: 0 }}
                target="_blank"
                rel={"noopener noreferrer nofollow"}
              >
                <img
                  style={{ padding: 0, margin: 0, border: 0 }}
                  alt="Árukereső.hu"
                  src="https://static.arukereso.hu/hu/logo-120.png"
                  loading="lazy"
                />
              </a>
              <a
                title="Árukereső.hu"
                style={{
                  lineHeight: "16px",
                  fontSize: "11px",
                  fontFamily: "Arial, Verdana",
                  color: "#000",
                }}
                href="https://www.arukereso.hu/"
                target="_blank"
                rel={"noopener noreferrer nofollow"}
              >
                Árukereső.hu
              </a>
            </div>
            {partners.map((partner, index) => (
              <a
                href={partner.url}
                key={`partner-${index}`}
                target={"__blank"}
                rel={"noopener noreferrer nofollow"}
              >
                <img
                  src={`${process.env.GATSBY_CMS_URL}${partner.logo.url}`}
                  alt={partner.name}
                  loading="lazy"
                ></img>
              </a>
            ))}
          </Slider>
        </div>
      </LayoutHeaderFooter>
    </>
  );
};

export default IndexPage;
