import React from "react";
import decor from "../../assets/images/FirstComponentMainPage/decor.svg";
import decor_mobile from "../../assets/images/FirstComponentMainPage/decor_mobile.svg";
import "./firstComponentMainPage.scss";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const FirstComponentMainPage = ({
  primary_color,
  secondary_color,
  image,
  content,
}) => {
  return (
    <>
      <div className="firstComponentMainPage-wrapper">
        <div
          className="decoration"
          style={
            primary_color && secondary_color
              ? {
                  background: `radial-gradient(16.69% 21.44% at 53.13% 82.93%, ${secondary_color} 0%, ${primary_color} 100%)`,
                }
              : primary_color
              ? { backgroundColor: primary_color }
              : null
          }
        ></div>
        <div className="component-content">
          <section>
            <div className="content-wrapper">
              <div className="list-wrapper">
                <ul>
                  {content.items.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={item.url}>
                          <div className="icon">
                            <img
                              src={`${process.env.GATSBY_CMS_URL}${item.icon.url}`}
                              alt={item.icon_alt}
                              loading="lazy"
                            />
                          </div>
                          <div>{item.item}</div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <div className="igen-wrapper">
                  <section>
                    <img src={decor} alt="decor" loading="lazy" />
                  </section>
                  <section id="yes">IGEN</section>
                </div>
              </div>
              <h1 className="title">{content.title}</h1>
              <div className="description">
                <ReactMarkdown
                  children={content.description}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
            </div>
          </section>
          <section>
            <div className="image-wrapper">
              <img src={image} alt="Ez a CMO" loading="lazy" />
            </div>
          </section>
        </div>
      </div>

      <div className="firstComponentMainPage-wrapper tablet">
        <div
          className="decoration"
          style={
            primary_color && secondary_color
              ? {
                  background:
                    "radial-gradient(62.51% 63.05% at 44.9% 80.33%, #891CFE 30.08%, #5107FF 90.87%)",
                }
              : primary_color
              ? { backgroundColor: primary_color }
              : null
          }
        ></div>
        <div className="component-content">
          <section>
            <div className="content-wrapper">
              <div className="list-wrapper">
                <ul>
                  {content.items.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={item.url}>
                          <div className="icon">
                            <img
                              src={`${process.env.GATSBY_CMS_URL}${item.icon.url}`}
                              alt={item.icon_alt}
                              loading="lazy"
                            />
                          </div>
                          <div>{item.item}</div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <div className="igen-wrapper">
                  <section>
                    <img src={decor} alt="decor" loading="lazy"/>
                  </section>
                  <section id="yes">IGEN</section>
                </div>
              </div>
              <h1 className="title">{content.title}</h1>
              <div className="description">
                <ReactMarkdown
                  children={content.description}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
            </div>
          </section>
          <section className="image-section">
            <div className="image-wrapper">
              <img src={image} alt="Ez a CMO" loading="lazy"/>
            </div>
          </section>
        </div>
      </div>

      <div className="firstComponentMainPage-wrapper mobile">
        <div
          className="decoration"
          style={
            primary_color && secondary_color
              ? {
                  background:
                    "radial-gradient(62.51% 63.05% at 44.9% 80.33%, #891CFE 30.08%, #5107FF 90.87%)",
                }
              : primary_color
              ? { backgroundColor: primary_color }
              : null
          }
        ></div>
        <div className="component-content">
          <section>
            <div className="content-wrapper">
              <div className="list-wrapper">
                <div className="igen-wrapper">
                  <section>
                    <img src={decor_mobile} alt="decor mobile" loading="lazy"/>
                  </section>
                </div>
                <ul>
                  {content.items.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={item.url}>
                          <div className="icon">
                            <img
                              src={`${process.env.GATSBY_CMS_URL}${item.icon.url}`}
                              alt={item.icon_alt}
                              loading="lazy"
                            />
                          </div>
                          <div>{item.item}</div>
                        </a>
                      </li>
                    );
                  })}
                  <li>
                    <div className="title">
                      <strong>IGEN. </strong>Ez a CMO.
                    </div>
                  </li>
                </ul>
              </div>

              <div className="description">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={content.description}
                />
              </div>
            </div>
          </section>
          <section className="image-section">
            <div className="image-wrapper">
              <img src={image} alt="Ez a CMO" loading="lazy"/>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default FirstComponentMainPage;
