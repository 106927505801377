import React from "react";
import "./paymentMethods.scss";

const PaymentMethods = ({ title, content }) => {
  return (
    <div className="payment-methods-wrapper">
      <h2 className="payment-methods-title">{title}</h2>
      <div className="payment-methods">
        {content.map((paymentMethod, index) => (
          <img
            className="payment-method"
            key={`payment-method-${index}`}
            src={process.env.GATSBY_CMS_URL + paymentMethod.image.url}
            alt={paymentMethod.name}
            loading="lazy"
          ></img>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethods;
