import React from "react";

import kotelezo from "../../assets/images/TitleSubtitleCards/kotelezo-fejlec-ikon.svg";

import CheckIcon from "@material-ui/icons/Check";

import LayoutComponent from "../LayoutComponent/LayoutComponent";

import "./titleSubtitleCards.scss";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const TitleSubtitleCards = ({ title, subtitle, content, background_color }) => {
  return (
    <div className="titleSubtitleCards-wrapper">
      <LayoutComponent background_color={background_color}>
        <div className="body-content">
          <h1 className="title">{title}</h1>
          <p className="subtitle">{subtitle}</p>
          <div className="cards">
            {content.map((card, index) => {
              return (
                <div className="card" key={index}>
                  <div className="kotelezo">
                    <img src={kotelezo} alt="kötelező fejléc ikon" />
                    Kötelező
                  </div>
                  <p className="titl">{card.title}</p>
                  <div className="desc">
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={card.description}
                    />
                  </div>
                  <CheckIcon />
                </div>
              );
            })}
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default TitleSubtitleCards;
