import React, { useState, useEffect } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import './downloadIcons.scss';

const DownloadIcons = (props) => {
  const data = useStaticQuery(graphql`
    query downloadIcons {
      appStore: imageSharp(
        fluid: { originalName: { eq: "app-store.png" } }
      ) {
        fluid(maxWidth: 200, quality: 100) {
          src
          srcWebp
        }
      }
      googleplaystore: imageSharp(
        fluid: { originalName: { eq: "google-play.png" } }
      ) {
        fluid(maxWidth: 200, quality: 100) {
          src
          srcWebp
        }
      }
      huawei: imageSharp(
        fluid: { originalName: { eq: "huawei-appgallery.png" } }
      ) {
        fluid(maxWidth: 200, quality: 100) {
          src
          srcWebp
        }
      }
    }
  `);

  const [src, setSrc] = useState(['']);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.chrome) {
        setSrc([
          data.appStore.fluid.srcWebp,
          data.googleplaystore.fluid.srcWebp,
          data.huawei.fluid.srcWebp,
        ]);
      } else {
        setSrc([
          data.appStore.fluid.src,
          data.googleplaystore.fluid.src,
          data.huawei.fluid.src,
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { column } = props;
  return (
    <div
      className="downloadIcons-wrapper"
      style={
        column
          ? {
              gridTemplateColumns: '160px',
              gridTemplateRows: '50px 50px 50px'
            }
          : null
      }
    >
      <a
        href="https://apps.apple.com/app/apple-store/id1514390651"
        target={'__blank'}
        rel={'noopener noreferrer nofollow'}
      >
        <img src={src[0]} alt="appstore" loading="lazy"/>
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.cmo24.cmocompany"
        target={'__blank'}
        rel={'noopener noreferrer nofollow'}
      >
        <img src={src[1]} alt="google play store" loading="lazy"/>
      </a>
      <a
        href="https://appgallery.huawei.com/#/app/C103947461"
        target={'__blank'}
        rel={'noopener noreferrer nofollow'}
      >
        <img src={src[2]} alt="huawei appgallery" loading="lazy"/>
      </a>
    </div>
  );
};

export default DownloadIcons;
