import "./iconCardComponent.scss";

import React from "react";

const IconCardComponent = ({ category }) => {
  if (category.slug === "/online-szamlazo-kategoria") {
    category.slug = "/online-szamlazo";
  }
  return (
    <a className="category-card" href={category.slug}>
      <img
        className="category-card-img"
        src={`${process.env.GATSBY_CMS_URL}${category.icon?.url}`}
        alt={category.iconAlt}
      />
      <p className="category-card-title">{category.cardTitle}</p>
      <p className="category-card-description">{category.cardDescription}</p>
    </a>
  );
};

export default IconCardComponent;
